import React from 'react'
import { graphql, Link } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import header from '../images/CleanAir-Desktop-Header-1920x340-Curve-About-2x.png'
import headerMobile from '../images/CleanAir-Mobile-Header-1080x550-Curve-About-2x.png'
import people from '../images/people.png'

import { Container, Row, Col } from 'reactstrap'
import Img from 'gatsby-image'

class AboutIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const content = get(this, 'props.data.contentfulAbout.content')
    const title = get(this, 'props.data.contentfulAbout.title')
    const links = get(this, 'props.data.contentfulAbout.links')
    const metaDescription = get(
      this,
      'props.data.contentfulAbout.metaDescription'
    )
    const metaImageURL = get(
      this,
      'props.data.contentfulAbout.metaImage.file.url'
    )

    return (
      <Layout location={this.props.location}>
        <div>
          <Helmet title={title + ' | ' + siteTitle}>
            <meta name="description" content={metaDescription} />
            <meta
              property="og:url"
              content={`https://cleanairdev.co.uk/about`}
            />
            <meta property="og:type" content={'website'} />
            <meta
              property="og:title"
              content={title + ' | ' + siteTitle}
            />
            <meta property="og:description" content={metaDescription} />
            <meta property="og:image" content={metaImageURL} />
          </Helmet>
          <div className="page-banner page-banner--disclaimer">
            <img className="d-none d-lg-block" src={header} alt="" />
            <img className="d-block d-lg-none" src={headerMobile} alt="" />{' '}
            <h1>{title}</h1>
          </div>
          <Container className="mt-5 mb-5 pb-5">
            <Row>
              <Col sm={12} lg={6} className="my-auto">
                <Img className="br-10" fluid={content.image.fluid} />{' '}
              </Col>
              <Col
                sm={12}
                lg={{ size: 5, offset: 1 }}
                className="mt-5 mt-lg-0 about-us"
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: content.text.childMarkdownRemark.html,
                  }}
                ></div>
              </Col>
            </Row>
          </Container>
          <div className="about-we-also pt-5 pb-5 text-center">
            <Container className="pt-5 pb-5">
              <h2 className="pb-4">We also work with:</h2>
              {links.map((l, i) => (
                <a
                  className={`btn btn-about btn-about--${i} mr-2 ml-2`}
                  key={i}
                  href={l.link}
                >
                  {l.name}
                </a>
              ))}
            </Container>
          </div>
        </div>
      </Layout>
    )
  }
}

export default AboutIndex

export const pageQuery = graphql`
  query AboutQuery {
    site {
      siteMetadata {
        title
      }
    }
    contentfulAbout {
      title
      metaDescription
      metaImage {
        file {
          url
        }
      }
      links {
        link
        name
      }
      content {
        text {
          childMarkdownRemark {
            html
          }
        }
        image {
          fluid(maxWidth: 670, maxHeight: 400) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`
